export const PARTICLES_CONFIG = {
  particles: {
    number: {
      value: 70,
      density: { enable: true, value_area: 1000 }
    },
    color: { value: '#707f8c' },
    shape: {
      type: 'circle',
      stroke: { width: 0 }
    },
    opacity: {
      value: 0.69,
      random: true
    },
    size: {
      value: 0,
      random: false
    },
    line_linked: {
      enable: true,
      distance: 192.40944730386272,
      color: '#707f8c',
      opacity: 0.20042650760819036,
      width: 1
    },
    move: {
      enable: true,
      speed: 4,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: { enable: false, rotateX: 600, rotateY: 1200 }
    }
  },
  interactivity: {
    detect_on: 'window',
    events: {
      onhover: { enable: true }
    }
  },
  retina_detect: true
};

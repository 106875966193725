import React from 'react';
import DataProvider from '../../services/dataProvider.service';
import pageWrapper from '../../components/PageWrapper/PageWrapper';
import { NavLink, withRouter } from 'react-router-dom';
import Release from './Release/Release';
import './MusicDetails.scss';
import Compilation from './Compilation/Compilation';
import Remix from './Remix/Remix';

class MusicDetails extends React.Component {
  state = {
    data: null
  };

  constructor(props) {
    super(props);
    const { setLoadingState, setError } = this.props;
    setLoadingState(true);
    DataProvider.getRelease(this.props.match.params.slug, this.props.match.params.schemaKey).subscribe(
      release => {
        console.log('release', release);
        this.setState({ data: release });
        setLoadingState(false);
      },
      () => {
        setError();
        setLoadingState(false);
      }
    );
  }

  render() {
    const { dataIsLoading } = this.props;
    const { data } = this.state;
    return (
      <>
        {!dataIsLoading && !!data && (
          <>
            <NavLink to={'/music'} className="Link--NoBorder">
              <p>
                <small>&larr; go back</small>
              </p>
            </NavLink>
            {data.template === 'release' && <Release release={data} />}
            {data.template === 'compilation' && <Compilation release={data} />}
            {data.template === 'remix' && <Remix release={data} />}
          </>
        )}
      </>
    );
  }
}

const MusicDetailsPage = withRouter(pageWrapper(MusicDetails));

export default MusicDetailsPage;

import React from 'react';
// import logo from './logo.svg';
import './App.scss';
import Particles from 'react-particles-js';
import { PARTICLES_CONFIG } from './constants/particles-config';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import MusicPage from './views/Music/Music';
import Video from './views/Video';
import Photo from './views/Photo';
import AboutPage from './views/About/About';
import ContactPage from './views/Contact/Contact';
import CodePage from './views/Code/Code';
import MusicDetailsPage from './views/MusicDetails/MusicDetails';
import { Helmet } from 'react-helmet';

class App extends React.Component {
  render() {
    return (
      <SimpleReactLightbox showCaption={false}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Piotr Michałowski</title>
          <meta name="description" content="Piotr Michałowski - electronic music producer" />
        </Helmet>
        <Router>
          <Particles
            width={'100%'}
            height={'100%'}
            params={PARTICLES_CONFIG}
            style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, zIndex: -1 }}
          />
          <div className="Container">
            <Header />
            <Switch>
              <Route path="/" exact>
                <AboutPage />
              </Route>
              <Route exact path="/music">
                <MusicPage />
              </Route>
              <Route path="/music/:schemaKey/:slug">
                <MusicDetailsPage />
              </Route>
              <Route path="/video">
                <Video />
              </Route>
              <Route path="/photo">
                <Photo />
              </Route>
              <Route path="/code">
                <CodePage />
              </Route>
              <Route path="/contact">
                <ContactPage />
              </Route>
              <Route path="/">
                <AboutPage />
              </Route>
            </Switch>
            <Footer />
          </div>
        </Router>
      </SimpleReactLightbox>
    );
  }
}

export default App;

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import flamelink from 'flamelink';
// import 'flamelink/content';
// import 'flamelink/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBzJDMGpGgzAu3JtpSlMGyhYKRQR8PLnzo',
  authDomain: 'piotrmichalowski-ee4da.firebaseapp.com',
  databaseURL: 'https://piotrmichalowski-ee4da.firebaseio.com',
  projectId: 'piotrmichalowski-ee4da',
  storageBucket: 'piotrmichalowski-ee4da.appspot.com',
  messagingSenderId: '543747940325'
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const flameLinkService = flamelink({
  firebaseApp
});

export default flameLinkService;

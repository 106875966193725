import React from 'react';
import './Loader.scss';

function Loader() {
  return (
    <div className="Loader">
      <div className="Loader__Spinner">
        <div />
        <div />
        <div />
        <div />
      </div>
      <p className="Loader__Text">Loading...</p>
    </div>
  );
}

export default Loader;

import React from 'react';
import Loader from '../Loader/Loader';

const pageWrapper = WrappedComponent => {
  return class extends React.Component {
    state = {
      loaderQueue: [],
      error: null
    };

    setLoadingState(loading) {
      if (loading) {
        this.setState({ loaderQueue: [...this.state.loaderQueue, true] });
      } else {
        this.setState({
          loaderQueue: this.state.loaderQueue.length ? [].fill(true, 0, this.state.loaderQueue.length - 1) : []
        });
      }
    }

    setError() {
      this.setState({ error: true });
    }

    render() {
      const { loaderQueue, error } = this.state;
      return (
        <main>
          {!!loaderQueue.length && <Loader />}
          {!error && (
            <WrappedComponent
              {...this.props}
              setLoadingState={this.setLoadingState.bind(this)}
              dataIsLoading={!!loaderQueue.length}
              setError={this.setError.bind(this)}
            />
          )}
          {error && <p>An error occurred while loading the data. Please try again later.</p>}
        </main>
      );
    }
  };
};

export default pageWrapper;

import React from 'react';
import './Header.scss';
import { NavLink } from 'react-router-dom';

function Header() {
  const ROUTES = ['Music', 'Code', 'Contact'];
  return (
    <header className="Header">
      <h1 className="Header__Title">
        <NavLink to="/" className="Header__TitleLink">
          Piotr Michałowski
        </NavLink>
      </h1>
      <nav>
        <ul className="Header__NavigationList">
          <li className="Header__NavigationListItem">
            <NavLink to="/" className="Header__NavigationLink" activeClassName="Header__NavigationLink--active" exact>
              About
            </NavLink>
          </li>
          {ROUTES.map((route, index) => (
            <li className="Header__NavigationListItem" key={index}>
              <NavLink
                to={'/' + route.toLowerCase()}
                className="Header__NavigationLink"
                activeClassName="Header__NavigationLink--active"
              >
                {route}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;

import React from 'react';
import DataProvider from '../../services/dataProvider.service';
import pageWrapper from '../../components/PageWrapper/PageWrapper';
import './Music.scss';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import stripHtml from 'string-strip-html';
import { switchMap, tap } from 'rxjs/operators';
import moment from 'moment';

class Music extends React.Component {
  dataFetchSubscription;

  state = {
    header: null,
    musicText: null,
    compilationsInfo: null,
    remixesInfo: null,
    artistNames: [],
    releases: [],
    compilations: [],
    remixes: [],
    performances: []
  };

  constructor(props) {
    super(props);
    const { setLoadingState, setError } = this.props;
    setLoadingState(true);
    this.dataFetchSubscription = DataProvider.getPerformancesList()
      .pipe(
        tap(performances => {
          this.setState({
            performances: Object.values(performances)
              .map(performance => {
                return {
                  date: moment(performance.date),
                  description: performance.description
                };
              })
              .sort((a, b) => {
                return a.date.format('x') - b.date.format('x');
              })
              .map(performance => {
                return {
                  ...performance,
                  date: performance.date.format('DD.MM.YYYY')
                };
              })
              .reverse()
          });
        }),
        switchMap(() => DataProvider.getMusicPageContent())
      )
      .subscribe(
        musicPage => {
          // console.log(musicPage);
          this.setState({
            header: musicPage.main.header,
            musicText: musicPage.main.musicText,
            compilationsInfo: musicPage.main.compilationsInfo,
            remixesInfo: musicPage.main.remixesInfo,
            artistNames: musicPage.artistNames,
            releases: musicPage.releases,
            compilations: musicPage.compilations,
            remixes: musicPage.remixes
          });
          setLoadingState(false);
        },
        () => {
          setError();
          setLoadingState(false);
        }
      );
  }

  checkIfArtistHasAnyReleases(artist) {
    return !!this.state.releases.find(release => release.artist[0].id === artist.id);
  }

  render() {
    const {
      header,
      musicText,
      compilationsInfo,
      remixesInfo,
      artistNames,
      releases,
      compilations,
      remixes,
      performances
    } = this.state;
    const { dataIsLoading } = this.props;
    return (
      <>
        {!dataIsLoading && (
          <article className="Music">
            <Helmet>
              <title>Music | Piotr Michałowski</title>
              {musicText && <meta name="description" content={stripHtml(musicText)} />}
              <meta property="og:type" content="article" />
            </Helmet>
            {!!header && <h2 className="Music__Header">{header}</h2>}
            <section className="Music__MainText Section" dangerouslySetInnerHTML={{ __html: musicText }} />
            <section className="Music__ReleasesWrap">
              {artistNames
                .filter(artist => this.checkIfArtistHasAnyReleases(artist))
                .map(artist => (
                  <div key={artist.id} className="Music__ArtistSection">
                    <h3>{artist.name}</h3>
                    <p>{artist.description}</p>
                    <ul className="Music__ReleasesList">
                      {releases
                        .filter(release => release.artist[0].id === artist.id)
                        .map(release => (
                          <li key={release.id} className="Music__ReleasesListElement">
                            <NavLink to={'/music/releases/' + release.slug} className="Link--NoBorder">
                              <img
                                className="Music__ReleaseCover"
                                src={release.coverUrl}
                                alt={artist.name + ' - ' + release.title + ' cover'}
                              />
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            </section>
            {compilations.length > 0 && (
              <section className="Music__ReleasesWrap">
                <h3>Compilation appearances</h3>
                <p>{compilationsInfo}</p>
                <ul className="Music__ReleasesList">
                  {compilations.map(release => (
                    <li key={release.id} className="Music__ReleasesListElement">
                      <NavLink to={'/music/compilations/' + release.slug} className="Link--NoBorder">
                        <img
                          className="Music__ReleaseCover"
                          src={release.coverUrl}
                          alt={'VA - ' + release.compilationTitle + ' cover'}
                        />
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {remixes.length > 0 && (
              <section className="Music__ReleasesWrap">
                <h3>Remixes</h3>
                <p>{remixesInfo}</p>
                <ul className="Music__ReleasesList">
                  {remixes.map(release => (
                    <li key={release.id} className="Music__ReleasesListElement">
                      <NavLink to={'/music/remixes/' + release.slug} className="Link--NoBorder">
                        <img
                          className="Music__ReleaseCover"
                          src={release.coverUrl}
                          alt={
                            release.originalArtist +
                            ' - ' +
                            release.trackTitle +
                            ' (' +
                            release.artist[0].name +
                            ' remix) cover'
                          }
                        />
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </section>
            )}

            {performances.length > 0 && (
              <section className="Music__PerformancesWrap">
                <h3>Performances</h3>
                <p>Here you'll find a list of my past live performances:</p>
                <ul className="Music__PerformancesList">
                  {performances.map(performance => (
                    <li key={performance.id} className="Music__PerformancesListElement">
                      {performance.date} : {performance.description}
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </article>
        )}
      </>
    );
  }
}
const MusicPage = pageWrapper(Music);

export default MusicPage;

import { from, of, zip } from 'rxjs';
import flameLinkService from './flameLink.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { shuffle } from 'lodash';

class DataProvider {
  // About
  static getAboutPageContent() {
    return from(flameLinkService.content.get('about')).pipe(
      // fetch the press pack url if it's set
      switchMap(about => {
        return about.pressPack && about.pressPack.length
          ? from(flameLinkService.storage.getURL(about.pressPack[0])).pipe(
              map(pressPackUrl => {
                return { ...about, pressPack: pressPackUrl };
              })
            )
          : of(about);
      }),
      switchMap(about => {
        // fetch image urls if there are some in the gallery
        if (about.gallery && about.gallery.length) {
          const galleryImages = about.gallery.map(element => {
            const galleryElement = {
              alt: element.alternativeText,
              fileId: element.image[0]
            };
            return from(flameLinkService.storage.getURL(element.image[0])).pipe(
              switchMap(imageUrl => {
                galleryElement.url = imageUrl;
                return from(flameLinkService.storage.getURL(element.image[0], { size: { width: 375, height: 9999 } }));
              }),
              map(thumbImageUrl => {
                galleryElement.thumbUrl = thumbImageUrl;
                return galleryElement;
              })
            );
          });
          return zip(...galleryImages).pipe(
            map(gallery => {
              return { ...about, gallery };
            })
          );
        } else {
          // return the response if there is no gallery
          return of(about);
        }
      })
    );
  }

  static getContactPageContent() {
    return from(flameLinkService.content.get('contact'));
  }

  static getCodePageContent() {
    return from(flameLinkService.content.get('code')).pipe(
      map(response => {
        response.technologies = shuffle(
          response.technologies.map(technology => {
            return { key: technology.uniqueKey, value: technology.name, count: technology.strength, color: '#fff' };
          })
        );
        return response;
      })
    );
  }

  static getMusicPageContent() {
    return zip(
      from(flameLinkService.content.get('music')),
      from(flameLinkService.content.get('artistNames')).pipe(
        map(artistNames => Object.values(artistNames).sort((a, b) => a.order - b.order))
      ),
      from(flameLinkService.content.get('releases', { populate: ['artist'] })).pipe(
        switchMap(releases => {
          const releasesArray = Object.values(releases).sort((a, b) => a.order - b.order);
          const coverImageRequests = [];
          releasesArray.forEach(release => {
            coverImageRequests.push(
              from(flameLinkService.storage.getURL(release.cover[0], { size: { width: 375, height: 9999 } })).pipe(
                tap(coverUrl => (release.coverUrl = coverUrl))
              )
            );
          });
          return zip(...coverImageRequests).pipe(map(() => releasesArray));
        })
      ),
      from(flameLinkService.content.get('compilations', { populate: ['artist'] })).pipe(
        switchMap(compilations => {
          const compilationsArray = Object.values(compilations).sort((a, b) => a.order - b.order);
          const coverImageRequests = [];
          compilationsArray.forEach(release => {
            coverImageRequests.push(
              from(flameLinkService.storage.getURL(release.cover[0], { size: { width: 375, height: 9999 } })).pipe(
                tap(coverUrl => (release.coverUrl = coverUrl))
              )
            );
          });
          return zip(...coverImageRequests).pipe(map(() => compilationsArray));
        })
      ),
      from(flameLinkService.content.get('remixes', { populate: ['artist'] })).pipe(
        switchMap(compilations => {
          const remixesArray = Object.values(compilations).sort((a, b) => a.order - b.order);
          const coverImageRequests = [];
          remixesArray.forEach(release => {
            coverImageRequests.push(
              from(flameLinkService.storage.getURL(release.cover[0], { size: { width: 375, height: 9999 } })).pipe(
                tap(coverUrl => (release.coverUrl = coverUrl))
              )
            );
          });
          return zip(...coverImageRequests).pipe(map(() => remixesArray));
        })
      )
    ).pipe(
      map(([main, artistNames, releases, compilations, remixes]) => {
        return { main, artistNames, releases, compilations, remixes };
      })
    );
  }

  static getRelease(slug, schemaKey) {
    return from(flameLinkService.content.getByField(schemaKey, 'slug', slug, { populate: ['artist', 'cover'] })).pipe(
      map(release => Object.values(release)[0])
    );
  }

  static getPerformancesList() {
    return from(flameLinkService.content.get('performances'));
  }
}

export default DataProvider;

import React from 'react';
import pageWrapper from '../../components/PageWrapper/PageWrapper';
import DataProvider from '../../services/dataProvider.service';
import { TagCloud } from 'react-tagcloud';
import './Code.scss';
import stripHtml from 'string-strip-html';
import { Helmet } from 'react-helmet';

class Code extends React.Component {
  dataFetchSubscription;

  state = {
    codeText: null,
    codeHeader: null,
    technologies: []
  };

  constructor(props) {
    super(props);
    const { setLoadingState, setError } = this.props;
    setLoadingState(true);
    this.dataFetchSubscription = DataProvider.getCodePageContent().subscribe(
      contact => {
        this.setState({
          codeText: contact.codeText,
          codeHeader: contact.header,
          technologies: contact.technologies
        });
        setLoadingState(false);
      },
      () => {
        setError();
        setLoadingState(false);
      }
    );
  }

  componentWillUnmount() {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
  }

  render() {
    const { codeText, codeHeader, technologies } = this.state;
    const { dataIsLoading } = this.props;
    return (
      <>
        {!dataIsLoading && (
          <article className="Code">
            <Helmet>
              <title>Code | Piotr Michałowski</title>
              {codeText && <meta name="description" content={stripHtml(codeText)} />}
              <meta property="og:type" content="article" />
            </Helmet>
            {!!codeHeader && <h2 className="Code__Header">{codeHeader}</h2>}
            <section className="Code__MainText Section" dangerouslySetInnerHTML={{ __html: codeText }} />
            <div className="Code__CloudWrapper">
              <TagCloud className="Code__Cloud" minSize={12} maxSize={32} tags={technologies} />
            </div>
          </article>
        )}
      </>
    );
  }
}

const CodePage = pageWrapper(Code);

export default CodePage;

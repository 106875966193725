import React from 'react';
import { Helmet } from 'react-helmet';
import stripHtml from 'string-strip-html';

export default class Release extends React.Component {
  render() {
    const { release } = this.props;
    return (
      <article>
        <Helmet>
          <title>
            {release.artist[0].name} - {release.title} | Piotr Michałowski
          </title>
          <meta name="description" content={stripHtml(release.description)} />
          <meta property="og:type" content="music.album" />
          <meta property="og:image" content={release.cover[0].url} />
        </Helmet>
        <div className="MusicDetails__ReleaseData">
          <div className="MusicDetails__CoverContainer">
            <img
              src={release.cover[0].url}
              className="ImgResponsive"
              alt={`${release.artist[0].name} - ${release.title}`}
            />
            {!!release.link && !!release.linkCta && (
              <a
                className="Link--NoBorder MusicDetails__BuyLink"
                href={release.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {release.linkCta}
              </a>
            )}
          </div>
          <div className="MusicDetails__ReleaseMeta">
            <h1>{release.title}</h1>
            <h2>{release.artist[0].name}</h2>
            {!!release.catalogNumber && (
              <p>
                Catalog number: <strong>{release.catalogNumber}</strong>
              </p>
            )}
            {!!release.label && (
              <p>
                Label: <strong>{release.label}</strong>
              </p>
            )}
            {!!release.releaseDate && (
              <p>
                Release date: <strong>{release.releaseDate}</strong>
              </p>
            )}
            {!!release.description && <p dangerouslySetInnerHTML={{ __html: release.description }} />}
            {!!release.bandcampEmbed && (
              <div className="MusicDetails__Embed" dangerouslySetInnerHTML={{ __html: release.bandcampEmbed }} />
            )}
            {!!release.soundcloudEmbed && (
              <div className="MusicDetails__Embed" dangerouslySetInnerHTML={{ __html: release.soundcloudEmbed }} />
            )}
            {!!release.youtubeEmbed && (
              <div className="MusicDetails__Embed" dangerouslySetInnerHTML={{ __html: release.youtubeEmbed }} />
            )}
          </div>
        </div>
      </article>
    );
  }
}

import React from 'react';
import pageWrapper from '../../components/PageWrapper/PageWrapper';
import { FacebookProvider, Page } from 'react-facebook';
import './Contact.scss';
import DataProvider from '../../services/dataProvider.service';
import stripHtml from 'string-strip-html';
import { Helmet } from 'react-helmet';

class Contact extends React.Component {
  dataFetchSubscription;

  state = {
    contactHeader: null,
    contactText: null,
    socialMedia: []
  };

  constructor(props) {
    super(props);
    const { setLoadingState, setError } = this.props;
    setLoadingState(true);
    this.dataFetchSubscription = DataProvider.getContactPageContent().subscribe(
      contact => {
        this.setState({
          contactHeader: contact.header,
          contactText: contact.contactText,
          socialMedia: contact.socialMedia || []
        });
        setLoadingState(false);
      },
      () => {
        setError();
        setLoadingState(false);
      }
    );
  }

  componentWillUnmount() {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
  }

  render() {
    const { contactHeader, contactText, socialMedia } = this.state;
    const { dataIsLoading } = this.props;
    return (
      <FacebookProvider appId="400342967278074">
        {!dataIsLoading && (
          <>
            <Helmet>
              <title>Contact | Piotr Michałowski</title>
              {contactText && <meta name="description" content={stripHtml(contactText)} />}
              <meta property="og:type" content="article" />
            </Helmet>
            {!!contactHeader && <h2 className="Contact__Header">{contactHeader}</h2>}
            <div className="Contact">
              <div className="Contact__Column">
                <p className="Contact__Text">{contactText}</p>
                {!!socialMedia && socialMedia.length > 0 && (
                  <ul className="Contact__SocialMediaList">
                    {socialMedia.map((account, index) => {
                      return (
                        <li className="Contact__SocialMediaListItem" key={index}>
                          <a href={account.url} target="_blank" rel="noopener noreferrer">
                            {account.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="Contact__Column--Social">
                <Page href="https://www.facebook.com/PiotrMichalowski01" width={320} />
              </div>
            </div>
          </>
        )}
      </FacebookProvider>
    );
  }
}

const ContactPage = pageWrapper(Contact);

export default ContactPage;

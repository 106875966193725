import React from 'react';
import pageWrapper from '../../components/PageWrapper/PageWrapper';
import { SRLWrapper } from 'simple-react-lightbox';
import DataProvider from '../../services/dataProvider.service';
import './About.scss';
import { Helmet } from 'react-helmet';

class About extends React.Component {
  dataFetchSubscription;

  state = {
    header: null,
    aboutText: null,
    gallery: [],
    pressPack: null
  };

  constructor(props) {
    super(props);
    const { setLoadingState, setError } = this.props;
    setLoadingState(true);
    this.dataFetchSubscription = DataProvider.getAboutPageContent().subscribe(
      about => {
        this.setState({
          header: about.header,
          aboutText: about.aboutText,
          gallery: about.gallery || [],
          pressPack: about.pressPack
        });
        setLoadingState(false);
      },
      () => {
        setError();
        setLoadingState(false);
      }
    );
  }

  componentWillUnmount() {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
  }

  render() {
    const { header, aboutText, gallery, pressPack } = this.state;
    const { dataIsLoading } = this.props;
    return (
      <>
        {!dataIsLoading && (
          <>
            <Helmet>
              <title>Piotr Michałowski</title>
              <meta
                name="description"
                content="Electronic music producer, amateur filmmaker and senior web developer."
              />
              <meta property="og:type" content="profile" />
              <meta property="og:profile:first_name" content="Piotr" />
              <meta property="og:profile:last_name" content="Michałowski" />
              <meta property="og:image" content={gallery.length ? gallery[0].url : ''} />
            </Helmet>
            <article className="About">
              {!!header && <h2 className="About__Header">{header}</h2>}
              <section className="About__MainText Section" dangerouslySetInnerHTML={{ __html: aboutText }} />
              <SRLWrapper>
                <section className="About__Gallery Section">
                  {gallery.length !== 0 &&
                    gallery.map(galleryElement => {
                      return (
                        <a
                          key={galleryElement.fileId}
                          href={galleryElement.url}
                          data-attribute="SRL"
                          className="About__GalleryLink Link--NoBorder"
                        >
                          <img
                            src={galleryElement.thumbUrl}
                            alt={galleryElement.alt}
                            className="ImgResponsive About__GalleryImage"
                          />
                        </a>
                      );
                    })}
                </section>
              </SRLWrapper>
              {!!pressPack && (
                <section>
                  <p>
                    <a href={pressPack} target="_blank" rel="noopener noreferrer">
                      Press pack
                    </a>
                  </p>
                </section>
              )}
            </article>
          </>
        )}
      </>
    );
  }
}

const AboutPage = pageWrapper(About);

export default AboutPage;
